import React from "react"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../../components/layout"
import style from "./index.module.css"
import masterBuilders from "./master-builders.png"
import AndvicHeader from "../../../components/andvic-header"
import BurgerMenu from "../../../components/burger-menu"

import Carousel, { Modal, ModalGateway, ViewType } from "react-images"
import SEO from "../../../components/seo"

export interface IAppProps {
  data: any
}

export interface IAppState {
  modalIsOpen: boolean
  selectedIndex?: number
}

class Gallery extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props)

    this.state = {
      modalIsOpen: false,
      selectedIndex: 0,
    }
  }

  toggleLightbox = (selectedIndex: number = 0) => {
    this.setState(state => ({
      modalIsOpen: !state.modalIsOpen,
      selectedIndex,
    }))
  }

  public render() {
    const { data } = this.props

    const images = data.galleryImages.edges.map((x: any) => ({
      source: x.node.childImageSharp.fluid.src,
      originalName: x.node.childImageSharp.fluid.originalName,
    }))

    const sortedImage = [...images].sort((a: any, b: any) =>
      a.originalName > b.originalName
        ? 1
        : b.originalName > a.originalName
        ? -1
        : 0
    )

    const sortedWithCaption = sortedImage.map((x: any) => ({
      ...(x.originalName.includes("tom-polo") && {
        caption: "2019 Floating Fables by artist Tom Polo",
      }),
      ...(x.originalName.includes("jonny-niesche") && {
        caption: "2021 Burger with the light by artist Jonny Niesche",
      }),

      ...x,
    }))

    const { modalIsOpen, selectedIndex } = this.state

    return (
      <>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal
              closeOnBackdropClick={true}
              closeOnEsc={true}
              onClose={_e => {
                this.toggleLightbox(0)
              }}
            >
              <Carousel
                currentIndex={selectedIndex}
                styles={{
                  footerCaption: (base, state) => ({
                    ...base,
                    // display: "none",
                  }),
                  view: base => ({
                    ...base,
                    "& > img": {
                      margin: "auto",
                    },
                  }),
                }}
                views={sortedWithCaption}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <div onClick={_e => this.toggleLightbox(0)}>
          <Img fluid={data.lounge.childImageSharp.fluid} />
        </div>
        <div onClick={_e => this.toggleLightbox(2)}>
          <Img fluid={data.outdoor.childImageSharp.fluid} />
        </div>
        <div onClick={_e => this.toggleLightbox(2)}>
          <Img fluid={data.lounge2.childImageSharp.fluid} />
        </div>
        <div onClick={_e => this.toggleLightbox(3)}>
          <Img fluid={data.bathroom.childImageSharp.fluid} />
        </div>
      </>
    )
  }
}

const BackgroundSection = ({
  className,
  data,
  header,
}: {
  className: any
  data: any
  header: any
}) => {
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <>
      <BackgroundImage Tag="section" className={className} fluid={imageData}>
        <AndvicHeader></AndvicHeader>

        <h1 className={style.pageHeader}>
          <span>{header}</span>
        </h1>
      </BackgroundImage>
    </>
  )
}

export default function IndexPage(): JSX.Element {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(
        relativePath: {
          eq: "elizabeth-bay/TFPhoto_Corben_Toft Monks_0035_PS.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }

      lounge: file(name: { eq: "1-lounge1-jonny-niesche" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      outdoor: file(name: { eq: "2-art1-jonny-niesche" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      lounge2: file(name: { eq: "3-art2" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      bathroom: file(name: { eq: "4-bathroom-eb" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      galleryImages: allFile(
        filter: { relativeDirectory: { eq: "elizabeth-bay/gallery" } }
      ) {
        edges {
          node {
            relativePath
            relativeDirectory
            dir
            childImageSharp {
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }

      portfolioOne: file(relativePath: { eq: "elizabeth-bay/5-kitchen.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Elizabeth Bay - Portfolio" />
      <BurgerMenu></BurgerMenu>

      <BackgroundSection
        data={data}
        className={style.header}
        header="Elizabeth Bay"
      ></BackgroundSection>

      <div className={style.portfolioMain}>
        <div>
          <h2 className={style.portfolioSubheader}>
            Prestigious Apartment Lifestyle
          </h2>
          <p>
            In one of the most desirable waterfront addresses in Elizabeth Bay
            and views over looking Sydney Harbour, a transformation of an
            existing 1960s apartment into a modern contemporary and luxurious
            living space. Designed by award winning Corben Architects and built
            by Andvic Projects, there has been no compromises to quality while
            building within the constraints of the existing structure.
          </p>
          <p>
            Integral to the service provided by Andvic Projects, the following
            were key aspects attributing to the success of the project and the
            challenge of minimal disruption to neighbouring residents.
          </p>
          <ul className={style.bulletList}>
            <li>Advanced planning and provision of work notices</li>
            <li>
              Open communication / collaboration with Client, Architect,
              Building and Strata Management
            </li>
            <li>Managing expectations by delivering on time and to budget</li>
          </ul>

          <p>
            Andvic Projects had been awarded the prestigious Master Builders
            Association (NSW) Excellence in State Awards 2023 - Home Unit
            Renovations and is a testament to our commitment in delivering a
            high quality outcome for our clients
          </p>
          <p style={{ textAlign: "center", marginTop: "32px" }}>
            <a
              href="https://www.mbansw.asn.au/excellence-awards/andvic-projects-winner"
              target="_blank"
            >
              <img
                style={{ display: "inline" }}
                width="220"
                src={masterBuilders}
              ></img>
            </a>
          </p>

          <p className={style.attribution}>Photos by Tom Ferguson</p>
        </div>
        <div className={style.home2}>
          <div>
            <Img fluid={data.portfolioOne.childImageSharp.fluid} />
          </div>
        </div>
      </div>
      <div className={style.portfolioGallery}>
        <Gallery data={data} />
      </div>
    </Layout>
  )
}
